import {
    HIDE_CONFIRMATION_DIALOG,
    HIDE_DOWNLOAD_DIALOG,
    SHOW_CONFIRMATION_DIALOG,
    SHOW_DOWNLOAD_DIALOG,
    SHOW_ERROR,
    SIMPLE_DIALOG_CLOSE,
    USER_MENU_ENABLED
} from "./types";
import axios from "./interceptor";
import { BASE_URL } from "../constant";
import { handleError } from "../utils/utils";

export const simpleDialogClose = () => {
    return({
        type: SIMPLE_DIALOG_CLOSE
    })
}

export const showError = (error) => {
    return ({
        type: SHOW_ERROR,
        payload: error.response
    })
}

export const showConfirmationDialog = (id, previousValue, newValue, entity, field, action, valueType, onAccept, dynamicContent) => {
    return({
        type: SHOW_CONFIRMATION_DIALOG,
        payload: {
            entity: entity,
            entityId: id,
            field: field,
            action: action,
            newValue: newValue,
            previousValue: previousValue,
            valueType: valueType,
            onAccept: onAccept,
            dynamicContent: dynamicContent
        }
    })
}

export const showDownloadDialog = () => {
    return({
        type: SHOW_DOWNLOAD_DIALOG,
    })
}

export const hideConfirmationDialog = () => {
    return ({
        type: HIDE_CONFIRMATION_DIALOG
    })
}

export const hideDownloadDialog = () => {
    return ({
        type: HIDE_DOWNLOAD_DIALOG
    })
}

export const getUserMenuEnabled = (path) => async dispatch => {
    axios.get(`${BASE_URL}/current/configAuthExcluded/getAllowViewUserMenu`)
    .then(response => {
            console.log("response", response);
    dispatch({
        type: USER_MENU_ENABLED,
        payload: response
    })
    }).catch(error => {
        handleError(error, dispatch);
    })
}