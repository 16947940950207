import {
    HIDE_CONFIRMATION_DIALOG,
    REQUEST_START,
    REQUEST_SUCCESS,
    SHOW_CONFIRMATION_DIALOG,
    SHOW_DOWNLOAD_DIALOG,
    HIDE_DOWNLOAD_DIALOG,
    SHOW_CONTENT_LOADER,
    SHOW_BACKDROP_LOADER,
    SIMPLE_DIALOG_CLOSE,
    SHOW_ERROR,
    SHOW_SUCCESS,
    REQUEST_FAILURE,
    USER_MENU_ENABLED
} from "../actions/types";

const INITIAL_STATE = {
    dialogMessage: '',
    entity: '',
    dialogResponseMessage: null,
    uploadResponseMessage: {
        recordsProcessed: null,
        failedRecords: [],
    },
    confirmationDialog: {
        entity: '',
        entityId: '',
        field: '',
        action: '',
        previousValue: null,
        newValue: null,
        valueType: null,
        open: false,
        onAccept: '',
        dynamicContent: null
    },
    downloadDialog: {
        open: false
    },
    refresh: false,
    dialogOpen: false,
    dialogType: '',
    showContentLoader: false,
    showBackDropLoader: false,
    requestInProgress: false,
    requestSuccess: false,
    userMenuEnabled: false
}

const commonReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SHOW_ERROR:
            return {
                ...state,
                dialogOpen: true,
                dialogMessage: action.payload.data.translationKey,
                dialogType: 'danger',
                requestInProgress: false
            }
        case SHOW_CONTENT_LOADER:
            return {
                ...state,
                showContentLoader: action.payload
            }
        case SHOW_BACKDROP_LOADER:
            return {
                ...state,
                showBackDropLoader: action.payload
            }
        case SIMPLE_DIALOG_CLOSE:
            return {
                ...state,
                dialogOpen: false,
                dialogMessage: '',
                dialogType: '',
                uploadResponseMessage: {
                    recordsProcessed: null,
                    failedRecords: []
                },
                refresh: false
            }
        case SHOW_CONFIRMATION_DIALOG:
            return {
                ...state,
                confirmationDialog: {
                    entity: action.payload.entity,
                    entityId: action.payload.entityId,
                    field: action.payload.field,
                    action: action.payload.action,
                    previousValue: action.payload.previousValue,
                    newValue: action.payload.newValue,
                    valueType: action.payload.valueType,
                    open: true,
                    onAccept: action.payload.onAccept,
                    dynamicContent: action.payload.dynamicContent
                }
            }
        case HIDE_CONFIRMATION_DIALOG:
            return {
                ...state,
                confirmationDialog: {
                    entity: '',
                    entityId: '',
                    field: '',
                    action: '',
                    previousValue: null,
                    newValue: null,
                    valueType: null,
                    open: false,
                    onAccept: ''
                }
            }
        case SHOW_DOWNLOAD_DIALOG:
            return {
                ...state,
                downloadDialog: {
                    open: true,
                }
            }
        case HIDE_DOWNLOAD_DIALOG:
            return {
                ...state,
                downloadDialog: {
                    open: false,
                }
            }
        case SHOW_SUCCESS:
            return {
                ...state,
                dialogOpen: true,
                dialogMessage: action.payload.message,
                dialogType: action.payload.dialogType,
                entity: action.payload.entity,
                uploadResponseMessage: {
                    recordsProcessed: action.payload.uploadResponse ? action.payload.uploadResponse.data.numberOfRecordsProcessed : undefined,
                    failedRecords: action.payload.uploadResponse? action.payload.uploadResponse.data.failedRecords : []
                },
                refresh: action.payload.refresh
            }
        case REQUEST_START:
            return {
                ...state,
                requestInProgress: true
            }
        case REQUEST_SUCCESS:
            return {
                ...state,
                requestInProgress: false,
                requestSuccess: true
            }
        case REQUEST_FAILURE:
            return {
                ...state,
                requestInProgress: false,
                requestSuccess: false
            }
        case USER_MENU_ENABLED:
            return {
                ...state,
                userMenuEnabled: action.payload.data
            }
        default:
            return state;
    }
}

export default commonReducer;